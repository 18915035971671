/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import lozad from "lozad";
import React, { useEffect } from "react";
import Alert from "./alert/Alert";
import Cart from "./cart/Cart";
import Footer from "./footer/Footer";
import "./layout.css";
import GlobalModal from "./modal/GlobalModal";
import Navigation from "./navigation/Navigation";

const Layout = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    lozad().observe();
  }, []);

  return (
    <>
      <Navigation />
      <Alert />
      <Cart />
      <main id="main-content">{children}</main>
      <Footer />
      <GlobalModal />
    </>
  );
};

export default Layout;
