import React from "react";
import { Input as BaseInput, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import styled from "styled-components";

const Group = styled(InputGroup)`
  align-items: flex-end;
`;

const StyledInput = styled(BaseInput).attrs(() => ({
  className: "wavy-input"
}))`
  color: #070d0f;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  border-bottom: 1px solid #959595;

  :focus {
    background: transparent;
    box-shadow: none;
  }
`;

const Addon = styled(InputGroupAddon).attrs(() => ({
  className: "wavy-input-addon"
}))``;

const AddOnWrapper = styled(InputGroupText).attrs(() => ({
  className: "wavy-input-addon-text"
}))`
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  border-bottom: 1px solid #959595;
  color: ${({ disabled }) => (disabled ? "#7b7b7b" : "#070d0f")};
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
`;

interface InputProps {
  className?: string;
  type?: string;
  addOn?: string;
  addOnDisabled?: boolean;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  rows?: number | string;
  onChange?: (event?: any) => void;
  onAddOnClick?: (event?: any) => void;
}

const Input = React.memo(
  ({ className, inputRef, addOn, addOnDisabled, onAddOnClick, ...rest }: InputProps) => (
    <Group className={className}>
      <StyledInput innerRef={inputRef} {...rest} />
      {!addOn ? null : (
        <Addon addonType="append">
          <AddOnWrapper onClick={onAddOnClick} disabled={addOnDisabled}>
            {addOn}
          </AddOnWrapper>
        </Addon>
      )}
    </Group>
  )
);

export default Input;
