import { OutboundLink } from "gatsby-plugin-google-analytics";
import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import ProductConstants from "../../constants/Products";
import { CartContext, CartProviderProps } from "../../contexts/CartContext";
import useMobile from "../../hooks/useMobile";
import { Button } from "../library/Button";
import { Cross } from "../library/Icons";
import { SubHeader, Text } from "../library/Text";
import LineItem from "./LineItem";

// NOTE: get sale info from CMS/Shopify to make this a network thing
const showBanner = false;

const CartWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 50%;
  left: 100%;
  z-index: 18;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  transition: left 0.3s ease;

  @media screen and (max-width: 768px) {
    left: 0;
    top: ${() => `${showBanner ? 30 : 0}px`};
    width: 100%;
    height: ${() => `calc(100% - ${showBanner ? 30 : 0}px)`};
  }
`;

const CartBody = styled.div`
  height: 100%;
  padding: 115px;
  background-color: white;

  @media screen and (max-width: 768px) {
    padding: 100px 20px;
  }

  @media screen and (min-width: 769px) and (max-width: 1040px) {
    padding: 115px 50px;
  }
`;

const CartInner = styled.div<{ size: number }>`
  overflow: auto;
  max-height: calc(100% - 136px);
  border-bottom: ${props => (props.size ? "1px solid #d3d3d3" : undefined)};
`;

const Backdrop = styled.div<{ isOpen: boolean }>`
  display: ${props => (!props.isOpen ? "none" : undefined)};
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 17;
  opacity: 0.5;
  background-color: #000;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CrossContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;

  @media screen and (max-width: 768px) {
    display: flex;

    svg {
      margin-left: 5px;
    }
  }
`;

const MobileText = styled.div`
  font-weight: bold;
  line-height: 16px;
`;

const CartHeader = styled(SubHeader)`
  margin-bottom: 70px;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Empty = styled(Text)`
  text-align: center;
  font-weight: 600;
`;

const CartItemList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Discount = styled.div<{ disabled: boolean }>`
  margin: 10px 0;

  span {
    float: right;
    color: ${props => (props.disabled ? "gray" : "#0e0e0e")};
    font-weight: 600;
  }
`;

const Savings = styled(Discount)`
  span {
    color: ${props => (props.disabled ? "gray" : "red")};
  }
`;

const Checkout = styled(Button)<{ $empty: boolean; $discount: boolean }>`
  width: 100%;
  height: 51px;
  background-color: #bce0f6;
  color: ${props => (props.disabled ? "white" : "#4b5a62")};
  margin-top: ${props => (!props.$discount ? "10px" : undefined)};
  display: ${props => (props.$empty ? "none" : undefined)};
`;

const comparator = (a, b) => {
  const s1 = a[0];
  const s2 = b[0];
  return s1 < s2 ? 1 : s1 > s2 ? -1 : 0;
};

const formatPrice = (x: number) => (Math.floor(x) === x ? x.toLocaleString() : x.toFixed(2));

const Cart = () => {
  // hooks
  const isMobile = useMobile();
  const slideTimeoutRef = useRef(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { isOpen, cart, checkoutUrl, loading, subTotal } = cartContext.state;
  const items = Object.entries(cart).sort(comparator) as [string, number][];
  const total = items.reduce((pv, [sku, q]) => pv + q * ProductConstants.Products[sku].price, 0);
  const discount = !!items.length && subTotal !== total;

  // functions
  const toggleCart = () => cartContext.dispatch({ type: "toggleCart" });

  // effects
  // disable scroll && make the banner stick to top of screen
  useLayoutEffect(() => {
    const el = document.getElementById("site-banner");

    if (isOpen) {
      if (el && isMobile) el.classList.add("fixed-cart-banner");

      document.documentElement.classList.add("disable-scroll");
    } else {
      if (el) el.classList.remove("fixed-cart-banner");

      // only remove the scroll if the mobile menu isn't open
      const isMenuOpen = document.documentElement.dataset.menuOpen === "true";
      if (!isMenuOpen) {
        document.documentElement.classList.remove("disable-scroll");
      }
    }
  }, [isOpen, isMobile]);

  // slide animation for cart
  useEffect(() => {
    window.clearTimeout(slideTimeoutRef.current);

    if (!!wrapperRef.current) {
      if (isOpen) {
        wrapperRef.current.style.display = "block";
        if (isMobile) {
          wrapperRef.current.style.left = "0";
        } else {
          window.setTimeout(() => {
            if (!!wrapperRef.current) {
              wrapperRef.current.style.left = "50%";
            }
          }, 20);
        }
      } else if (isMobile) {
        wrapperRef.current.style.display = "";
      } else {
        wrapperRef.current.style.left = "";
        slideTimeoutRef.current = window.setTimeout(() => {
          if (!!wrapperRef.current) {
            wrapperRef.current.style.display = "";
          }
        }, 300);
      }
    }
  }, [isOpen, isMobile]);

  return (
    <>
      <CartWrapper id="cart" ref={wrapperRef}>
        <CartBody>
          <CrossContainer onClick={toggleCart}>
            {!isMobile ? (
              <Cross stroke={5} />
            ) : (
              <>
                <MobileText>Close</MobileText>
                <Cross stroke={5} />
              </>
            )}
          </CrossContainer>
          <CartHeader>Cart</CartHeader>
          {!items.length ? <Empty>There are no items in your cart</Empty> : null}
          <CartInner size={items.length}>
            <CartItemList>
              {items.map(([sku, quantity]) => (
                <LineItem key={sku} sku={sku} quantity={quantity} />
              ))}
            </CartItemList>
          </CartInner>
          {discount ? (
            <>
              <Discount disabled={loading}>
                Subtotal<span>${formatPrice(total)}</span>
              </Discount>
              <Savings disabled={loading}>
                Discount<span>- ${formatPrice(total - subTotal)}</span>
              </Savings>
            </>
          ) : null}
          <OutboundLink target="_self" href={checkoutUrl}>
            <Checkout
              disabled
              $empty={!items.length}
              $discount={discount}
            >
              Checkout Now | ${formatPrice(subTotal)}
            </Checkout>
          </OutboundLink>
        </CartBody>
      </CartWrapper>
      <Backdrop id="cart-backdrop" onClick={toggleCart} isOpen={isOpen} />
    </>
  );
};

export default Cart;
