const debug = (source: string, event: string, params: any) => {
  console.debug(
    `Tracking disabled in dev environment. The event to be tracked is as follows:
Source: '${source}'
Event name: '${event}'
Event params:`,
    params
  );
};

/**
 * A static class used to track user behavior for several different
 * events.
 */
export default class Tracking {
  /**
   * Wrapper around facebook tracking api
   * @param {string} event - the name of the event you wish to track
   * @param {object} params - extra data that can be sent with the event
   */
  static fb = (event: string, params?: any) => {
    if (!(window as any).fbq) {
      debug("facebook", event, params);
    } else {
      (window as any).fbq("track", event, params);
    }
  };

  /**
   * Wrapper around pinterest tracking api
   * @param {string} event - the name of the event you wish to track
   * @param {object} params - extra data that can be sent with the event
   */
  static pintrk = (event: string, params?: any) => {
    if (!(window as any).pintrk) {
      debug("pinterest", event, params);
    } else {
      (window as any).pintrk("track", event, params);
    }
  };
}
