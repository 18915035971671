export const Actions = {
  SHOW_PRIVACY_POLICY: {
    type: "showPrivacy"
  },
  SHOW_TERMS: {
    type: "showTerms"
  },
  SHOW_RETURN_POLICY: {
    type: "showReturn"
  }
};
