import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navbar as BaseNavBar } from "reactstrap";
import styled from "styled-components";
import BrowserConstants from "../../constants/Browser";
import Tracking from "../../utils/tracking";
import Banner from "../banner/Banner";
import { Logo } from "../library/Icons";
import CartIcon from "./CartIcon";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";

// NOTE: get sale info from CMS/Shopify to make this a network thing
const showBanner = false;

const Sentinel = styled.div`
  position: absolute;
  top: ${() => `${showBanner ? 40 : 0}px`};
  height: 70px;
  width: 100%;
  z-index: -1;

  @media screen and (max-width: 768px) {
    top: ${() => `${showBanner ? 30 : 0}px`};
  }
`;

const Wrapper = styled.div`
  position: relative;
  top: 0;
  height: 70px;
  width: 100%;
  color: white;
  z-index: 16;

  /* try to resolve fixed issue */
  transform: translate3d(0, 0, 0);
`;

const NavigationWrapper = styled.div`
  line-height: 70px;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
`;

const NavBar = styled(BaseNavBar)`
  padding: 0;

  @media screen and (max-width: 768px) {
    width: 33%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 0 1rem;
  position: relative;

  svg {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    width: 33%;
    justify-content: flex-end;
  }
`;

const MobileLogoWrapper = styled.div`
  width: 34%;
  text-align: center;

  svg {
    max-height: 29px;
    max-width: 145px;
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const Toggle = styled.button.attrs<{ className?: string }>(() => ({
  type: "button",
  "aria-label": "Toggle navigation",
  className: "navbar-toggler"
}))`
  border: none;

  :focus {
    outline: none;
  }

  @media screen and (width: 768px) {
    display: block !important;
  }
`;

const CartButton = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const topHeaderBlackPages = ["products", "formula"];
const staticBlackPages = ["privacy", "terms"];

const Navigation = () => {
  // hooks
  const location = useLocation();
  const openRef = useRef(false);
  const bannerRef = useRef(null);
  const toggleRef = useRef(null);
  const stickyRef = useRef(false);
  const wrapperRef = useRef(null);
  const sentinelRef = useRef(null);
  const observerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // variables
  const isStatic = staticBlackPages.some(route => location.pathname.includes(route));
  const isBlack = topHeaderBlackPages.some(route => location.pathname.includes(route));
  const toggleClass = `wavy-toggler-close${isBlack ? "-alt" : ""}`;
  let color = isBlack ? "black" : undefined;
  let navColor = color === "black" ? "white" : "black";

  if (isStatic) {
    color = undefined;
    navColor = "black";
  }

  // functions
  // toggle the mobile menu
  const toggleMenu = useCallback(
    (override, scrollTop = true) => {
      // change isOpen
      openRef.current = typeof override === "boolean" ? override : !openRef.current;

      // if override
      if (typeof override === "boolean" && !override) {
        // enable scroll
        document.documentElement.dataset.menuOpen = "false";
        document.documentElement.classList.remove("disable-scroll");

        // remove fixed banner
        bannerRef.current.classList.remove("fixed-banner");

        // reset toggle icon
        toggleRef.current.classList.remove("wavy-toggler-open");
        toggleRef.current.classList.add(toggleClass);
      } else {
        // toggle scroll
        document.documentElement.dataset.menuOpen = openRef.current.toString();
        document.documentElement.classList.toggle("disable-scroll");

        // toggle banner style
        bannerRef.current.classList.toggle("fixed-banner");

        // toggle toggler icon
        toggleRef.current.classList.toggle("wavy-toggler-open");
        toggleRef.current.classList.toggle(toggleClass);
      }

      // toggle mobile menu visibility
      const mobileMenu = document.getElementById("mobile-nav");
      if (mobileMenu) {
        mobileMenu.style.display = openRef.current ? "block" : "";
      }

      // toggle wrapper style
      wrapperRef.current.style.top = openRef.current && showBanner ? "30px" : "";
      wrapperRef.current.style.position = openRef.current || stickyRef.current ? "fixed" : "";
      wrapperRef.current.style.borderBottom = openRef.current
        ? "1px solid #dbdbdb"
        : stickyRef.current || isStatic
        ? `1px solid ${navColor}`
        : "";
      wrapperRef.current.style.backgroundColor = openRef.current
        ? "white"
        : stickyRef.current || isStatic
        ? navColor
        : "";

      // if the menu is closed && scrolling is enabled, scroll to the top
      if (!openRef.current && scrollTop) {
        window.scrollTo(0, 0);
      }

      // update state
      setIsOpen(openRef.current);
    },
    [toggleClass, navColor, isStatic]
  );

  // close the mobile menu (and track)
  const closeMenu = useCallback(
    (scrollTop = true) => {
      Tracking.fb("ViewContent");
      toggleMenu(false, scrollTop);
    },
    [toggleMenu]
  );

  // close menu if you resize while menu is open
  const onResize = useCallback(() => {
    if (window.innerWidth > 768 && openRef.current) {
      toggleMenu(false, false);
    }
  }, [toggleMenu]);

  // effects
  // if you resize while the menu is open
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  // listen to when navbar sticks
  useEffect(() => {
    // instantiate observer
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        stickyRef.current = entry.intersectionRatio !== 1;

        // change sticky properties for nav
        wrapperRef.current.style.position = stickyRef.current ? "fixed" : "";
        wrapperRef.current.style.backgroundColor = stickyRef.current || isStatic ? navColor : "";
        wrapperRef.current.style.borderBottom =
          stickyRef.current || isStatic ? `1px solid ${navColor}` : "";

        // change top for main content
        const main = document.getElementById("main-content");
        if (main) {
          main.style.marginTop = stickyRef.current ? "70px" : "";
        }
      },
      { threshold: BrowserConstants.THRESHOLDS }
    );

    // observe && disconnect
    observerRef.current.observe(sentinelRef.current);
    return () => {
      observerRef.current.disconnect();
    };
  }, [navColor, isStatic]);

  return (
    <header id="site-nav">
      <Banner ref={bannerRef} />
      <Sentinel ref={sentinelRef} />
      <Wrapper ref={wrapperRef}>
        <NavigationWrapper>
          <NavBar expand="md">
            <Toggle
              ref={toggleRef}
              className={toggleClass}
              onClick={() => toggleMenu(undefined, false)}
            >
              <span className="navbar-toggler-icon" />
            </Toggle>
            <MainMenu color={color} />
          </NavBar>
          <MobileLogoWrapper>
            <Link to="/" onClick={() => closeMenu()}>
              <Logo color={color} />
            </Link>
          </MobileLogoWrapper>
          <IconWrapper>
            <CartButton>
              <CartIcon color={isOpen ? "black" : color} />
            </CartButton>
          </IconWrapper>
        </NavigationWrapper>
      </Wrapper>
      <MobileMenu closeMenu={closeMenu} />
    </header>
  );
};

export default Navigation;
