import React, { useContext } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ModalContext, ModalProviderProps } from "../../contexts/ModalContext";
import PrivacyPolicy from "../legal/PrivacyPolicy";
import ReturnPolicy from "../legal/ReturnPolicy";
import Terms from "../legal/Terms";

const GlobalModal = () => {
  const modalContext = useContext(ModalContext) as ModalProviderProps;

  const { isOpen, title, content } = modalContext.state;
  const toggle = () => {
    modalContext.dispatch({ type: "hideModal" });
  };

  let node = null;
  switch (content) {
    case "policy":
      node = <PrivacyPolicy />;
      break;
    case "return":
      node = <ReturnPolicy />;
      break;
    case "terms":
      node = <Terms />;
      break;
    default:
      break;
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{node}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GlobalModal;
