import { useState, useEffect, useCallback } from "react";

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const onResize = useCallback(() => {
    if ((window.innerWidth <= 768 && !isMobile) || (window.innerWidth > 768 && isMobile)) {
      setIsMobile(!isMobile);
    }
  }, [isMobile]);

  // initialize isMobile
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  // resize event listener
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return isMobile;
};

export default useMobile;
