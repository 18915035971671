import React, { useContext, useEffect, useRef } from "react";
import { Alert as BaseAlert } from "reactstrap";
import styled from "styled-components";
import { AlertContext, AlertProviderProps } from "../../contexts/AlertContext";

const StyledAlert = styled(BaseAlert)`
  opacity: 0;
  z-index: -2;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 1s;
`;

const Alert = () => {
  const alertRef = useRef(null);
  const timeoutRef = useRef(null);
  const removeRef = useRef(null);
  const alertContext = useContext(AlertContext) as AlertProviderProps;

  const { dispatch } = alertContext;
  const { id, isOpen, content, duration, alertType } = alertContext.state || {};

  useEffect(() => {
    window.clearTimeout(timeoutRef.current);
    window.clearTimeout(removeRef.current);
    if (isOpen) {
      alertRef.current.style.zIndex = "0";
      alertRef.current.style.opacity = "1";
      timeoutRef.current = window.setTimeout(() => {
        alertRef.current.style.opacity = "";
        removeRef.current = window.setTimeout(() => {
          alertRef.current.style.zIndex = "";
          dispatch({ type: "hideAlert" });
        }, 1000);
      }, 1000 + duration);
    }
  }, [id, isOpen, duration, dispatch]);

  return (
    <StyledAlert innerRef={alertRef} color={alertType}>
      {content}
    </StyledAlert>
  );
};

export default Alert;
