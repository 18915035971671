import React from "react";
import styled from "styled-components";

const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #a9cadd;
  margin-bottom: 12px;
`;

const Header = styled.h1`
  font-family: Quarto;
  font-style: normal;
  font-weight: bold;
  font-size: 66px;
  line-height: 1.2;
  text-align: center;

  color: white;
`;

const SubHeader = styled.h2`
  font-weight: bold;
  font-size: 55px;
  line-height: 1.2;
  margin-bottom: 36px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 25px;
`;

const Disclaimer = styled.div`
  font-size: 0.8rem;
  text-align: left;
  margin: 10px 0;
`;

const PreOrderInfo = ({ className }: { className?: string }) => (
  <Disclaimer className={className}>
    <sup>*</sup> Pre-order shipments can be expected to arrive within 3 weeks.
  </Disclaimer>
);

export { Title, Header, SubHeader, Paragraph, Text, PreOrderInfo };
