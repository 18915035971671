import React from "react";

interface IconProps {
  color?: string;
  className?: string;
}

interface LogoIconProps extends IconProps {
  width?: number;
  height?: number;
}

interface SizeableIcon extends IconProps {
  size?: string;
}

interface WidthIcon extends SizeableIcon {
  stroke?: number;
}

interface PlusIconProps {
  color?: string;
  className?: string;
  size?: string;
  x?: number | string;
  y?: number | string;
}

const Logo = React.memo(({ className, width, height, color = "#7ad4ff" }: LogoIconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 145 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M139.746 0.211342H138.724V0.00372314H140.992V0.211342H139.97V3.93737H139.746V0.211342Z"
      fill={color}
    />
    <path
      d="M143.967 0.307737L142.866 3.94479H142.638L141.555 0.307737V3.94479H141.327V0.00372314H141.688L142.772 3.54067L143.833 0.00372314H144.195V3.93737H143.971L143.967 0.307737Z"
      fill={color}
    />
    <path
      d="M145 23.0828V23.8688C139.041 25.1331 134.021 26.5753 133.931 26.6012C133.835 26.6293 133.733 26.6171 133.646 26.5674C133.559 26.5176 133.494 26.4345 133.467 26.3361C133.44 26.2378 133.452 26.1324 133.5 26.043C133.548 25.9537 133.629 25.8878 133.725 25.8597C133.816 25.8301 138.933 24.3619 145 23.0828Z"
      fill={color}
    />
    <path
      d="M136.921 25.8338V25.6336H129.016V16.8765H135.3V12.6055H129.016V4.27474H136.921V0H124.44V28.2919C128.65 27.7657 132.821 26.9445 136.921 25.8338Z"
      fill={color}
    />
    <path
      d="M111.54 28.9926C112.494 28.9926 113.433 28.9926 114.43 28.9926C116.539 28.9592 118.579 28.848 120.569 28.6886C121.974 27.4095 122.714 25.4186 122.714 22.7566V0H118.38V23.068C118.38 25.2035 117.467 25.9747 116.011 25.9747C114.556 25.9747 113.639 25.2035 113.639 23.068V0H109.052V22.7714C109.052 25.6336 109.908 27.7432 111.54 28.9926Z"
      fill={color}
    />
    <path
      d="M108.171 28.9407V25.6336H100.653V0H96.0625V28.0546C99.7172 28.4847 103.715 28.8146 108.171 28.9407Z"
      fill={color}
    />
    <path
      d="M93.177 27.6801C94.1196 26.5012 94.6216 24.8847 94.6216 22.8122V20.3801C94.6216 17.2176 93.6646 14.9968 91.2089 14.0143V13.929C93.2493 12.9466 94.1666 11.0224 94.1666 7.94886V6.8774C94.1666 2.26528 92.1261 0 87.3844 0H80.4795V25.4593C84.3689 26.2157 88.5075 27.0239 93.177 27.6801ZM85.0551 4.27474H87.2616C88.8831 4.27474 89.591 5.21273 89.591 7.34825V9.01662C89.591 11.408 88.5509 12.1754 86.8463 12.1754H85.0406L85.0551 4.27474ZM85.0551 16.4464H87.0955C89.1756 16.4464 90.0496 17.3029 90.0496 20.0798V22.675C90.0496 24.8995 89.219 25.641 87.6769 25.641H85.0551V16.4464Z"
      fill={color}
    />
    <path
      d="M63.9212 23.2423C65.4716 23.2843 66.9968 23.3807 68.4968 23.5315V19.9945L74.275 0H69.9052L66.4527 13.6287H66.3697L62.9136 0H58.1357L63.9139 19.9945L63.9212 23.2423Z"
      fill={color}
    />
    <path
      d="M54.1705 23.6983L57.7313 0H53.5313L50.0933 24.2767C51.4331 24.0616 52.7873 23.8614 54.1705 23.6983Z"
      fill={color}
    />
    <path
      d="M49.9779 24.2952L46.5398 0H41.9209L45.6876 25.0626C47.1068 24.7772 48.5369 24.5325 49.9779 24.2952Z"
      fill={color}
    />
    <path
      d="M31.946 27.5689L32.3974 24.4806H38.0167L38.3092 26.5049C39.8116 26.2231 41.2922 25.9302 42.7584 25.6373L38.7643 0H32.0652L27.7026 28.0472C29.1472 27.9174 30.5917 27.7432 31.946 27.5689ZM35.1421 5.29801H35.2287L37.3955 20.4209H32.9788L35.1421 5.29801Z"
      fill={color}
    />
    <path
      d="M15.9153 28.0805L17.3598 13.9698H17.4429L18.9055 28.3326C19.393 28.3549 19.8553 28.3957 20.35 28.3957C22.0763 28.3957 23.7158 28.3289 25.312 28.2288L28.3745 0H24.3839L22.0546 23.7539H21.9715L19.7253 0H15.3122L13.1453 23.5834H13.0623L10.6535 0H6.20068L9.06089 26.3566C11.2739 27.1987 13.5737 27.7772 15.9153 28.0805Z"
      fill={color}
    />
    <path
      d="M9.40761 26.1193C9.38568 26.1653 9.35469 26.2062 9.31659 26.2393C9.27849 26.2725 9.23412 26.2971 9.18627 26.3118C9.13842 26.3264 9.08813 26.3307 9.03858 26.3244C8.98904 26.318 8.94131 26.3012 8.89841 26.275C6.07411 24.8808 3.08174 23.8784 0 23.2942V22.4933C3.20789 23.0901 6.32097 24.1355 9.25232 25.6002C9.29699 25.6224 9.33671 25.6539 9.36902 25.6925C9.40132 25.7312 9.42552 25.7763 9.44009 25.825C9.45466 25.8737 9.4593 25.925 9.45371 25.9756C9.44812 26.0263 9.43243 26.0752 9.40761 26.1193Z"
      fill={color}
    />
  </svg>
));

const Wave = ({ color = "#8fd4f1", className }: IconProps) => (
  <svg
    className={className}
    width="67"
    height="12"
    viewBox="0 0 67 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.3463 1.33227C66.3463 2.06782 65.5577 2.66454 64.5857 2.66454C61.8248 2.66454 60.3376 4.19438 58.2171 6.71341C56.1336 9.18973 53.7714 11.9958 48.8777 11.9958C43.9839 11.9958 41.6217 9.18973 39.5382 6.71341C37.4177 4.19438 35.9323 2.66454 33.1696 2.66454C30.4087 2.66454 28.9233 4.19438 26.8028 6.71208C24.7193 9.18973 22.3589 11.9958 17.4651 11.9958C12.5731 11.9958 10.2109 9.18973 8.12746 6.71341C6.00871 4.19572 4.52153 2.66588 1.76063 2.66588C0.788577 2.66588 0 2.06916 0 1.33227C0 0.595383 0.788577 0 1.76063 0C6.65439 0 9.01483 2.80604 11.0983 5.28236C13.2188 7.80006 14.706 9.3299 17.4651 9.3299C20.2243 9.3299 21.7114 7.80006 23.832 5.28236C25.9172 2.80604 28.2776 0 33.1714 0C38.0652 0 40.4274 2.80604 42.5108 5.28236C44.6313 7.80139 46.1168 9.33123 48.8794 9.33123C51.6421 9.33123 53.1275 7.80139 55.248 5.28236C57.3297 2.80604 59.6919 0 64.5857 0C65.5577 0 66.3463 0.596718 66.3463 1.33227Z"
      fill={color}
    />
  </svg>
);

const Person = ({ color = "white", className }: IconProps) => (
  <svg
    className={className}
    width="22"
    height="25"
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4444 24V21.4444C21.4444 20.0889 20.906 18.7888 19.9474 17.8303C18.9889 16.8718 17.6889 16.3333 16.3333 16.3333H6.11111C4.75556 16.3333 3.45553 16.8718 2.49701 17.8303C1.53849 18.7888 1 20.0889 1 21.4444V24"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2224 11.2222C14.0452 11.2222 16.3336 8.9339 16.3336 6.11111C16.3336 3.28832 14.0452 1 11.2224 1C8.39965 1 6.11133 3.28832 6.11133 6.11111C6.11133 8.9339 8.39965 11.2222 11.2224 11.2222Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Caret = ({ color = "white", className }: IconProps) => (
  <svg
    className={className}
    width="13"
    height="9"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.999999 0.999999L6.5 8L12 1" stroke={color} />
  </svg>
);

const Arrow = ({ color = "#2d7095", size = "md", className }: SizeableIcon) => {
  const d =
    size === "sm"
      ? "M7.29289 46.7071C7.68342 47.0976 8.31658 47.0976 8.7071 46.7071L15.0711 40.3431C15.4616 39.9526 15.4616 39.3195 15.0711 38.9289C14.6805 38.5384 14.0474 38.5384 13.6569 38.9289L8 44.5858L2.34314 38.9289C1.95262 38.5384 1.31945 38.5384 0.92893 38.9289C0.538406 39.3195 0.538406 39.9526 0.92893 40.3431L7.29289 46.7071ZM7 -4.37114e-08L7 46L9 46L9 4.37114e-08L7 -4.37114e-08Z"
      : "M10.9393 176.061C11.5251 176.646 12.4749 176.646 13.0607 176.061L22.6066 166.515C23.1924 165.929 23.1924 164.979 22.6066 164.393C22.0208 163.808 21.0711 163.808 20.4853 164.393L12 172.879L3.51472 164.393C2.92893 163.808 1.97919 163.808 1.3934 164.393C0.807611 164.979 0.807611 165.929 1.3934 166.515L10.9393 176.061ZM10.5 0V175H13.5V0H10.5Z";

  const width = size === "sm" ? "16" : "47";
  const height = size === "sm" ? "47" : "177";
  const viewBox = size === "sm" ? "0 0 16 47" : "0 0 24 177";

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={d} fill={color} />
    </svg>
  );
};

const Cross = ({ color = "#070d0f", size = "md", stroke = 2, className }: WidthIcon) => {
  const width = size === "sm" ? 18 : "17";
  const height = size === "sm" ? 18 : "17";
  const dimensionsLineOne = {
    x1: size === "sm" ? 13.2428 : 1.70711,
    y1: size === "sm" ? 4.7574 : 1.43498,
    x2: size === "sm" ? 4.75747 : 15.8492,
    y2: size === "sm" ? 13.2427 : 15.5771
  };

  const dimensionsLineTwo = {
    x1: size === "sm" ? 13.2426 : 1.29289,
    y1: size === "sm" ? 13.2425 : 15.435,
    x2: size === "sm" ? 4.75732 : 15.435,
    y2: size === "sm" ? 4.75723 : 1.29291
  };

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line stroke={color} strokeWidth={stroke} {...dimensionsLineOne} />
      <line stroke={color} strokeWidth={stroke} {...dimensionsLineTwo} />
    </svg>
  );
};

const Plus = ({ color = "black", size = "md", className, x, y }: PlusIconProps) => {
  const dimension = size === "sm" ? 9 : 12;
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={dimension}
      height={dimension}
      viewBox={`0 0 ${dimension} ${dimension}`}
      x={x}
      y={y}
    >
      {size === "sm" ? (
        <path
          d="M5.05788 3.792H8.24188V4.928H5.05788V8.224H3.90588V4.928H0.721875V3.792H3.90588V0.48H5.05788V3.792Z"
          fill={color}
        />
      ) : (
        <>
          <line x1="6" y1="4.37114e-08" x2="6" y2="12" stroke={color} strokeWidth="2" />
          <line x1="12" y1="6" x2="-8.74228e-08" y2="6" stroke={color} strokeWidth="2" />
        </>
      )}
    </svg>
  );
};

const Minus = ({ color = "#d3d3d3", className }: IconProps) => {
  return (
    <svg
      className={className}
      width="6"
      height="2"
      viewBox="0 0 6 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33 0.208H1.674C1.258 0.208 0.954 0.512 0.954 0.912C0.954 1.312 1.258 1.632 1.674 1.632H4.33C4.746 1.632 5.066 1.312 5.066 0.912C5.066 0.512 4.746 0.208 4.33 0.208Z"
        fill={color}
      />
    </svg>
  );
};

const Facebook = ({ color = "white", className }: IconProps) => (
  <svg
    className={className}
    width="11"
    height="20"
    viewBox="0 0 11 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0H8C5.24 0 3 2.24 3 5V8H0V12H3V20H7V12H10L11 8H7V5C7 4.45 7.45 4 8 4H11V0Z"
      fill={color}
    />
  </svg>
);

const Instagram = ({ color = "white", className }: IconProps) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 22H6C2.69 22 0 19.31 0 16V6C0 2.69 2.69 0 6 0H16C19.31 0 22 2.69 22 6V16C22 19.31 19.31 22 16 22ZM6 2C3.79 2 2 3.79 2 6V16C2 18.21 3.79 20 6 20H16C18.21 20 20 18.21 20 16V6C20 3.79 18.21 2 16 2H6Z"
      fill={color}
    />
    <path
      d="M11.0298 15.9598C9.96977 15.9598 8.92977 15.6198 8.05977 14.9798C6.98977 14.1798 6.28977 13.0198 6.08977 11.6998C5.68977 8.96978 7.56977 6.42979 10.2998 6.01979C10.7898 5.94979 11.2798 5.94979 11.7698 6.01979C13.9598 6.34979 15.6598 8.03978 15.9798 10.2298C16.1798 11.5498 15.8498 12.8698 15.0498 13.9398C14.2498 15.0098 13.0898 15.7098 11.7698 15.9098C11.5298 15.9398 11.2798 15.9598 11.0298 15.9598ZM11.0398 7.95979C10.8898 7.95979 10.7498 7.96978 10.5998 7.98978C8.96977 8.22978 7.82977 9.75978 8.07977 11.3998C8.19977 12.1898 8.61977 12.8898 9.25977 13.3698C9.89977 13.8498 10.6898 14.0498 11.4898 13.9298C12.2798 13.8098 12.9798 13.3898 13.4598 12.7498C13.9398 12.1098 14.1298 11.3198 14.0198 10.5198C13.8198 9.19979 12.8098 8.18979 11.4898 7.98978C11.3398 7.96978 11.1898 7.95979 11.0398 7.95979Z"
      fill={color}
    />
    <path
      d="M16.5098 6.5C15.9598 6.5 15.5098 6.05 15.5098 5.5C15.5098 4.95 15.9498 4.5 16.5098 4.5H16.5198C17.0698 4.5 17.5198 4.95 17.5198 5.5C17.5198 6.05 17.0598 6.5 16.5098 6.5Z"
      fill={color}
    />
  </svg>
);

const Speaker = ({ color = "white", className }: IconProps) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3711 0H1.93359C0.86741 0 0 0.86741 0 1.93359V16.0664C0 17.1326 0.86741 18 1.93359 18H10.3711C11.4373 18 12.3047 17.1326 12.3047 16.0664V1.93359C12.3047 0.86741 11.4373 0 10.3711 0ZM6.15234 1.49414C7.60623 1.49414 8.78906 2.67697 8.78906 4.13086C8.78906 5.58475 7.60623 6.76758 6.15234 6.76758C4.69846 6.76758 3.51562 5.58475 3.51562 4.13086C3.51562 2.67697 4.69846 1.49414 6.15234 1.49414ZM2.10938 6.92578C2.40061 6.92578 2.63672 7.16189 2.63672 7.45312C2.63672 7.74436 2.40061 7.98047 2.10938 7.98047C1.81814 7.98047 1.58203 7.74436 1.58203 7.45312C1.58203 7.16189 1.81814 6.92578 2.10938 6.92578ZM6.15234 16.5059C3.92305 16.5059 2.10938 14.6922 2.10938 12.4629C2.10938 10.2336 3.92305 8.41992 6.15234 8.41992C8.38164 8.41992 10.1953 10.2336 10.1953 12.4629C10.1953 14.6922 8.38164 16.5059 6.15234 16.5059ZM10.1953 7.98047C9.90408 7.98047 9.66797 7.74436 9.66797 7.45312C9.66797 7.16189 9.90408 6.92578 10.1953 6.92578C10.4865 6.92578 10.7227 7.16189 10.7227 7.45312C10.7227 7.74436 10.4865 7.98047 10.1953 7.98047Z"
      fill={color}
    />
    <path
      d="M6.15234 13.3418C6.63775 13.3418 7.03125 12.9483 7.03125 12.4629C7.03125 11.9775 6.63775 11.584 6.15234 11.584C5.66694 11.584 5.27344 11.9775 5.27344 12.4629C5.27344 12.9483 5.66694 13.3418 6.15234 13.3418Z"
      fill={color}
    />
    <path
      d="M6.15234 9.47461C4.50461 9.47461 3.16406 10.8152 3.16406 12.4629C3.16406 14.1106 4.50461 15.4512 6.15234 15.4512C7.80008 15.4512 9.14062 14.1106 9.14062 12.4629C9.14062 10.8152 7.80008 9.47461 6.15234 9.47461ZM6.15234 14.3965C5.08616 14.3965 4.21875 13.5291 4.21875 12.4629C4.21875 11.3967 5.08616 10.5293 6.15234 10.5293C7.21853 10.5293 8.08594 11.3967 8.08594 12.4629C8.08594 13.5291 7.21853 14.3965 6.15234 14.3965Z"
      fill={color}
    />
    <path
      d="M6.15234 5.71289C7.02468 5.71289 7.73438 5.00319 7.73438 4.13086C7.73438 3.25853 7.02468 2.54883 6.15234 2.54883C5.28001 2.54883 4.57031 3.25853 4.57031 4.13086C4.57031 5.00319 5.28001 5.71289 6.15234 5.71289ZM6.15234 3.60352C6.44358 3.60352 6.67969 3.83963 6.67969 4.13086C6.67969 4.42209 6.44358 4.6582 6.15234 4.6582C5.86111 4.6582 5.625 4.42209 5.625 4.13086C5.625 3.83963 5.86111 3.60352 6.15234 3.60352Z"
      fill={color}
    />
    <path
      d="M17.7085 12.4307L16.3022 11.7276C16.1388 11.6458 15.9446 11.6545 15.7892 11.7506C15.6337 11.8467 15.5391 12.0165 15.5391 12.1992V14.9266C15.374 14.8681 15.1966 14.8359 15.0117 14.8359C14.1394 14.8359 13.4297 15.5456 13.4297 16.418C13.4297 17.2903 14.1394 18 15.0117 18C15.884 18 16.5937 17.2903 16.5937 16.418V13.0525L17.2368 13.374C17.4973 13.5043 17.8141 13.3987 17.9443 13.1382C18.0746 12.8777 17.9689 12.5609 17.7085 12.4307Z"
      fill={color}
    />
    <path
      d="M17.708 2.58693L16.3018 1.88381C16.1383 1.80207 15.9441 1.81079 15.7887 1.90687C15.6332 2.00295 15.5386 2.17272 15.5386 2.35546V5.08289C15.3735 5.02435 15.1961 4.99218 15.0112 4.99218C14.1389 4.99218 13.4292 5.70188 13.4292 6.57421C13.4292 7.44655 14.1389 8.15625 15.0112 8.15625C15.8836 8.15625 16.5933 7.44655 16.5933 6.57421V3.20874L17.2363 3.53028C17.4968 3.66053 17.8136 3.55496 17.9439 3.29445C18.0741 3.03395 17.9685 2.71715 17.708 2.58693Z"
      fill={color}
    />
  </svg>
);

const BlockQuotes = ({ color = "#def0fb", className }: IconProps) => (
  <svg
    className={className}
    width="280"
    height="159"
    viewBox="0 0 280 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6245 61.5426V70.8456H57.9646V128.81H0.00142792L0 61.5426C0 39.3796 6.41565 21.0309 19.067 10.7341C27.8188 3.61139 38.4272 0 51.524 0V30.5077C44.4144 30.5077 28.6245 33.3535 28.6245 61.5426ZM99.47 61.5426V70.8456H128.81V128.81H70.8456V61.5426C70.8456 39.3796 76.5456 21.0309 89.197 10.7341C97.9487 3.61139 109.273 0 122.37 0V30.5077C115.26 30.5077 99.47 33.3535 99.47 61.5426Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251.135 97.4574V88.1544H221.795V30.1899H279.758L279.759 97.4574C279.759 119.62 273.344 137.969 260.692 148.266C251.941 155.389 241.332 159 228.235 159V128.492C235.345 128.492 251.135 125.647 251.135 97.4574ZM180.289 97.4574V88.1544H150.949V30.1899H208.914V97.4574C208.914 119.62 203.214 137.969 190.562 148.266C181.811 155.389 170.487 159 157.39 159V128.492C164.499 128.492 180.289 125.647 180.289 97.4574Z"
        fill={color}
      />
    </g>
  </svg>
);

const SmallBlockQuotes = ({ color = "#def0fb", className }: IconProps) => (
  <svg
    className={className}
    width="64"
    height="37"
    viewBox="0 0 64 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54836 14.079V16.2072H13.2604V29.4676H0.000326663L0 14.079C0 9.00878 1.46769 4.8112 4.36193 2.45561C6.36404 0.82617 8.7909 0 11.787 0V6.97917C10.1606 6.97917 6.54836 7.63021 6.54836 14.079ZM22.7556 14.079V16.2072H29.4676V29.4676H16.2072V14.079C16.2072 9.00878 17.5112 4.8112 20.4054 2.45561C22.4075 0.82617 24.9981 0 27.9942 0V6.97917C26.3678 6.97917 22.7556 7.63021 22.7556 14.079Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.3872 22.295V20.1668H50.6751V6.9064H63.9352L63.9355 22.295C63.9355 27.3652 62.4679 31.5628 59.5736 33.9184C57.5715 35.5479 55.1446 36.374 52.1485 36.374V29.3948C53.775 29.3948 57.3872 28.7438 57.3872 22.295ZM41.18 22.295V20.1668H34.4679V6.9064H47.7284V22.295C47.7284 27.3652 46.4244 31.5628 43.5301 33.9184C41.528 35.5479 38.9375 36.374 35.9413 36.374V29.3948C37.5678 29.3948 41.18 28.7438 41.18 22.295Z"
        fill={color}
      />
    </g>
  </svg>
);

const Ginseng = ({ color = "#93b1c3", className }: IconProps) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#ginseng0)">
      <path
        d="M30.0625 17V16.0625C30.0625 14.2572 29.3298 12.6201 28.1461 11.4324C28.1733 11.1093 28.1875 10.7779 28.1875 10.4375C28.1875 7.88812 26.7056 5.58313 24.4375 4.50688V0H18.8125V4.50688C18.4144 4.69562 18.0406 4.9225 17.695 5.18125L20.4131 7.89937L19.0869 9.22563L16.3706 6.50938C15.7107 7.39194 15.2743 8.43875 15.1227 9.55881C11.9174 9.98894 9.4375 12.7414 9.4375 16.0625H11.3125C11.3125 13.818 12.8986 11.9377 15.0086 11.4815C14.9961 11.5983 14.9818 11.7135 14.965 11.8269L18.5381 15.3994L17.2119 16.7256L14.3837 13.8969C12.8319 17.4897 8.85406 19.4193 6.21969 23.1506L6.16719 23.1637C2.536 24.0715 0 27.3196 0 31.0625V32H1.875V31.0625C1.875 28.8704 3.00588 26.8997 4.77756 25.7744C4.1795 27.2529 3.8125 28.9808 3.8125 31.0625V32H5.6875V31.0625C5.6875 29.2256 9.24187 25.1869 11.9919 23.9538C11.5775 27.3775 9.4375 28.9825 9.4375 32H11.3125C11.3125 30.1575 14.6581 27.0513 16.7463 24.3519C15.8156 23.0169 15.0625 21.3525 15.0625 18.875H16.9375C16.9375 24.1081 21.1189 24.9013 22.2691 29.4883H22.4568C22.1316 25.9199 21.1433 24.6996 20.8069 21.4438C22.0731 20.6912 23.4494 19.7519 24.6663 18.555L20.9619 14.8506L22.2881 13.5244L25.9144 17.1506C26.6411 16.2026 27.2482 15.123 27.6519 13.8904C27.9932 14.5402 28.1875 15.2789 28.1875 16.0625V17C28.1875 19.089 29.9445 20.75 32 20.75V18.875C30.9497 18.875 30.0625 18.0164 30.0625 17Z"
        fill={color}
      />
      <path
        d="M16.9206 24.5957C16.8419 25.9213 16.4856 26.7238 16.0806 27.6357C15.6038 28.7095 15.0625 29.927 15.0625 32.0007H16.9375C16.9375 29.3732 17.9681 28.5232 18.5037 26.522C18.0237 25.9483 17.4443 25.3101 16.9206 24.5957Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="ginseng0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Bamboo = ({ color = "#93b1c3", className }: IconProps) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8124 0.9375C13.8124 0.41975 13.3926 0 12.8749 0H7.8749C7.35715 0 6.9374 0.41975 6.9374 0.9375C6.9374 2.38275 6.87152 3.79294 6.73865 5.19381C6.47783 4.68806 6.13783 4.18675 5.69389 3.74281C3.87771 1.92675 1.10227 1.84569 0.984958 1.84312C0.748708 1.83706 0.517458 1.93013 0.349208 2.09838C0.180958 2.26656 0.0887702 2.49625 0.0939577 2.73412C0.0965202 2.85137 0.177583 5.62694 1.99371 7.443C2.44458 7.89387 2.95452 8.23769 3.46833 8.5C2.95458 8.76231 2.44458 9.10619 1.99371 9.557C0.177583 11.3731 0.0965827 14.1487 0.0940202 14.2659C0.0888327 14.5038 0.18102 14.7334 0.34927 14.9017C0.51302 15.0654 0.734895 15.1571 0.965895 15.1571C0.97227 15.1571 0.978583 15.1571 0.984958 15.1569C1.10221 15.1544 3.87771 15.0733 5.69383 13.2572C6.13733 12.8137 6.47715 12.313 6.73783 11.8077C6.87021 13.2023 6.9374 14.6039 6.9374 16.0001C6.9374 18.1901 6.77289 20.3938 6.44889 22.5626H14.3009C13.9769 20.3937 13.8124 18.19 13.8124 16.0001C13.8124 13.8102 13.9769 11.6064 14.3009 9.43756H7.50027C7.58283 8.93094 7.59277 8.58794 7.59365 8.54831C7.59402 8.53212 7.59277 8.51613 7.59227 8.50006C7.59283 8.484 7.59402 8.468 7.59365 8.45181C7.59277 8.41219 7.58283 8.06919 7.50027 7.56256H14.301C13.9769 5.38937 13.8124 3.16469 13.8124 0.9375Z"
      fill={color}
    />
    <path
      d="M6.93729 31.0625C6.93729 31.5802 7.35704 32 7.87479 32H12.8748C13.3925 32 13.8123 31.5802 13.8123 31.0625C13.8123 28.8014 13.9727 26.6259 14.3006 24.4375H6.44873C6.77273 26.6105 6.93729 28.8351 6.93729 31.0625Z"
      fill={color}
    />
    <path
      d="M28.5314 23.5C29.0451 23.2377 29.5551 22.8938 30.006 22.443C31.8221 20.6269 31.9032 17.8514 31.9057 17.7341C31.9109 17.4963 31.8187 17.2666 31.6505 17.0984C31.4822 16.9301 31.2516 16.8378 31.0147 16.8431C30.8975 16.8457 28.122 16.9267 26.3059 18.7429C25.8624 19.1864 25.5225 19.6871 25.2619 20.1923C25.1295 18.7977 25.0623 17.3961 25.0623 16C25.0623 13.8099 25.2268 11.6063 25.5508 9.4375H17.6989C18.0229 11.6064 18.1874 13.8101 18.1874 16C18.1874 18.1899 18.0229 20.3936 17.6989 22.5625H24.4995C24.4169 23.0691 24.407 23.4121 24.4061 23.4517C24.4057 23.4679 24.407 23.4839 24.4075 23.5C24.4069 23.5161 24.4057 23.5321 24.4061 23.5483C24.407 23.5879 24.4169 23.9309 24.4995 24.4375H17.6987C18.0228 26.6106 18.1874 28.8353 18.1874 31.0625C18.1874 31.5802 18.6071 32 19.1249 32H24.1249C24.6426 32 25.0624 31.5802 25.0624 31.0625C25.0624 29.6173 25.1282 28.2071 25.2611 26.8062C25.5219 27.3119 25.8619 27.8132 26.3059 28.2572C28.122 30.0732 30.8974 30.1543 31.0147 30.1569C31.0211 30.157 31.0274 30.1571 31.0338 30.1571C31.2648 30.1571 31.4867 30.0653 31.6504 29.9016C31.8187 29.7334 31.9109 29.5037 31.9057 29.2659C31.9031 29.1486 31.822 26.3731 30.0059 24.557C29.5551 24.1062 29.0451 23.7623 28.5314 23.5Z"
      fill={color}
    />
    <path
      d="M25.0625 0.9375C25.0625 0.41975 24.6428 0 24.125 0H19.125C18.6073 0 18.1875 0.41975 18.1875 0.9375C18.1875 3.19863 18.0272 5.37406 17.6992 7.5625H25.5511C25.2271 5.3895 25.0625 3.16488 25.0625 0.9375Z"
      fill={color}
    />
  </svg>
);

const CoconutOil = ({ color = "#93b1c3", className }: IconProps) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#coconut0)">
      <path
        d="M10.8457 18.6352C10.8457 13.9373 13.6741 9.88817 17.7171 8.09942C15.9471 5.37736 12.9382 3.72461 9.63962 3.72461C4.32431 3.72461 0 8.04892 0 13.3642C0 18.6795 4.32431 23.0039 9.63962 23.0039C10.3111 23.0039 10.9755 22.9348 11.625 22.7989C11.1222 21.507 10.8457 20.1029 10.8457 18.6352ZM2.5765 14.3017V12.4267H4.68488V14.3017H2.5765ZM6.79331 18.5185H4.68488V16.6435H6.79325V18.5185H6.79331ZM6.79331 10.0849H4.68488V8.20992H6.79325V10.0849H6.79331Z"
        fill={color}
      />
      <path
        d="M22.3604 24.0567C25.3553 24.0567 27.7833 21.6288 27.7833 18.6338C27.7833 15.6388 25.3553 13.2109 22.3604 13.2109C19.3654 13.2109 16.9375 15.6388 16.9375 18.6338C16.9375 21.6288 19.3654 24.0567 22.3604 24.0567Z"
        fill={color}
      />
      <path
        d="M22.3603 8.99609C17.045 8.99609 12.7207 13.3204 12.7207 18.6357C12.7207 23.951 17.045 28.2753 22.3603 28.2753C27.6756 28.2753 32 23.951 32 18.6357C32 13.3204 27.6756 8.99609 22.3603 8.99609ZM22.3603 25.9336C18.3363 25.9336 15.0625 22.6598 15.0625 18.6357C15.0625 14.6117 18.3363 11.3378 22.3603 11.3378C26.3844 11.3378 29.6582 14.6117 29.6582 18.6357C29.6582 22.6598 26.3843 25.9336 22.3603 25.9336Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="coconut0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export {
  Logo,
  Wave,
  Person,
  Caret,
  Arrow,
  Cross,
  Plus,
  Minus,
  Facebook,
  Instagram,
  Speaker,
  BlockQuotes,
  SmallBlockQuotes,
  Ginseng,
  Bamboo,
  CoconutOil
};
