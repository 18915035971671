import React, { useContext, useEffect, useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import styled from "styled-components";
import { CartContext, CartProviderProps } from "../../contexts/CartContext";
import useMobile from "../../hooks/useMobile";
import { Plus } from "../library/Icons";

const CartIconWrapper = styled.div`
  width: 49px;
  height: 49px;
  line-height: 49px;
  border: none;
  font-weight: bold;
  font-size: 15px;
  color: #090c0e;
  text-align: center;
`;

const SVGIcon = styled.svg`
  transition: width 0.4s, height 0.4s;

  &&& {
    overflow: visible;
  }
`;

const CartIcon = React.memo(({ color = "white" }: { color?: string }) => {
  // hooks
  const isMobile = useMobile();
  const sizeRef = useRef(-1);
  const iconRef = useRef(null);
  const timeoutRef = useRef(null);
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { cart } = cartContext.state;
  const size: number = Object.values(cart as any[]).reduce((pv: number, q: number) => pv + q, 0);

  // functions
  const toggleCart = () => cartContext.dispatch({ type: "toggleCart" });

  // effects
  // every time the size changes, do the grow opperation
  useEffect(() => {
    window.clearTimeout(timeoutRef.current);

    if (sizeRef.current > 0) {
      iconRef.current.classList.add("cart-icon");
      timeoutRef.current = setTimeout(() => {
        iconRef.current.classList.remove("cart-icon");
      }, 400);
    }

    sizeRef.current = size;
  }, [size]);

  return (
    <CartIconWrapper>
      <SVGIcon
        id="shopping-cart"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={iconRef}
        onClick={toggleCart}
      >
        <rect
          x="0.5"
          y="5.93475"
          width="24"
          height="17.4783"
          rx="2.5"
          stroke={color}
          strokeLinejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="24"
          height="5.52174"
          rx="1.5"
          stroke={color}
          strokeLinejoin="round"
        />
        <text x="50%" y="19" textAnchor="middle" fill={color}>
          {Math.min(size, 9)}
        </text>
        {size <= 9 ? null : (
          <g>
            <circle cx="25" cy="0" r="7" fill="#7ad4ff" />
            <Plus size="sm" x={20.5} y={-4} />
          </g>
        )}
      </SVGIcon>
      {isMobile || size ? null : (
        <UncontrolledTooltip placement="bottom" target="shopping-cart">
          Your cart is empty
        </UncontrolledTooltip>
      )}
    </CartIconWrapper>
  );
});

export default CartIcon;
