import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProductConstants from "../../constants/Products";
import { CartContext, CartProviderProps } from "../../contexts/CartContext";
import { getSubTotal, removeFromCart, updateCart } from "../../utils/shopify";
import { Cross, Minus, Plus } from "../library/Icons";
import { Text } from "../library/Text";

const CartItem = styled.li`
  margin-bottom: 20px;
  padding-bottom: 33px;
  border-bottom: 1px solid #d3d3d3;

  :last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const Content = styled.div`
  display: flex;
  height: 100px;
  position: relative;
`;

const ProductImage = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: -25px -5px;
    border-radius: 50%;
    background-color: #c5def5;
  }

  @media screen and (max-width: 768px) {
    width: 72px;
    height: 72px;

    img {
      object-position: -18px -5px;
    }
  }
`;

const TextContainer = styled.div<{ disabled: boolean }>`
  position: relative;
  padding-top: 7px;
  color: ${props => (props.disabled ? "gray" : undefined)};
`;

const ItemName = styled(Text)`
  line-height: 20px;
`;

const ItemSize = styled(Text)`
  line-height: 16px;
  margin-bottom: 18px;
  color: gray;
`;

const QuantityBox = styled.div<{ disabled: boolean }>`
  position: relative;
  border: 1px solid #d3d3d3;
  width: 100px;
  height: 36px;
  padding: 0 20px;
  background-color: ${props => (props.disabled ? "rgba(239, 239, 239, 0.3)" : undefined)};

  /* remove default input styling */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }
`;

const QuantityInput = styled.input.attrs(() => ({
  type: "number",
  inputmode: "numeric",
  pattern: "[0-9]*",
  min: 1,
  max: 10000
}))`
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
`;

const QuantityButton = styled.div<{ disabled?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const PlusButton = styled(QuantityButton)`
  right: 6px;
`;

const MinusButton = styled(QuantityButton)`
  left: 6px;
`;

const RemoveIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const Price = styled(Text)<{ disabled: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  color: #0e0e0e;
  font-weight: 600;
  color: ${props => (props.disabled ? "gray" : undefined)};
`;

const LineItem = React.memo(({ sku, quantity }: { sku: string; quantity: number }) => {
  // cart hook && variables
  const {
    dispatch,
    state: {
      loading,
      cart,
      metadata: { lineItems }
    }
  } = useContext(CartContext) as CartProviderProps;

  // hooks
  const timeoutRef = useRef(0);
  const [size, setSize] = useState(quantity);

  // variables
  const lineItemId = lineItems[sku];
  const currentQuantity = parseInt(cart[sku]);

  // functions
  const update = useCallback(
    (number, isIncrement) => Math.max(isIncrement ? number + 1 : number - 1, 1),
    []
  );

  const onNumberChange = useCallback(
    (isIncrement = false) => {
      if (loading || (!isIncrement && quantity === 1)) {
        return;
      }

      const newQuantity = update(currentQuantity, isIncrement);
      if (!isNaN(currentQuantity)) {
        updateCart([{ id: lineItemId, quantity: newQuantity }]).then(checkout => {
          dispatch({
            sku,
            type: "updateQuantity",
            quantity: newQuantity,
            subTotal: getSubTotal(checkout)
          });
        });
      }
    },
    [sku, quantity, loading, currentQuantity, lineItemId, dispatch, update]
  );

  const onRemoveItem = useCallback(() => {
    removeFromCart(lineItemId).then(checkout => {
      dispatch({
        sku,
        type: "removeFromCart",
        subTotal: getSubTotal(checkout)
      });
    });
  }, [sku, lineItemId, dispatch]);

  const onChange = useCallback(
    event => {
      const val = event.target.value;
      setSize(val);

      // update cart
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        updateCart([{ id: lineItemId, quantity: parseInt(val) }]).then(checkout => {
          dispatch({
            sku,
            type: "updateQuantity",
            quantity: val,
            subTotal: getSubTotal(checkout)
          });
        });
      }, 150);
    },
    [sku, lineItemId, dispatch]
  );

  // effects
  useEffect(() => {
    // every time the quantity changes, update the state
    setSize(quantity);

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [quantity]);

  return (
    <CartItem>
      <Content>
        <ProductImage>
          <img src={ProductConstants.Products[sku].img} alt={ProductConstants.Products[sku].alt} />
        </ProductImage>
        <TextContainer disabled={loading}>
          <ItemName>Curl & Coil Balm</ItemName>
          <ItemSize>({ProductConstants.Products[sku].size} oz)</ItemSize>
          <QuantityBox disabled={loading}>
            <MinusButton disabled={quantity === 1} onClick={() => onNumberChange()}>
              <Minus />
            </MinusButton>
            <QuantityInput disabled={loading} value={size} onChange={onChange} />
            <PlusButton onClick={() => onNumberChange(true)}>
              <Plus size="sm" color="#d3d3d3" />
            </PlusButton>
          </QuantityBox>
        </TextContainer>
        <RemoveIcon onClick={onRemoveItem}>
          <Cross color={loading ? "gray" : undefined} />
        </RemoveIcon>
        <Price disabled={loading}>{`$${(
          ProductConstants.Products[sku].price * quantity
        ).toLocaleString()}`}</Price>
      </Content>
    </CartItem>
  );
});

export default LineItem;
