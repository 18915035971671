/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

interface SeoProps {
  title: string;
  meta?: any[];
  lang?: string;
  description?: string;
}

const Seo = ({ description = "", lang = "en", meta = [], title }: SeoProps) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaData = [
    {
      name: `description`,
      content: metaDescription
    },
    {
      property: `og:title`,
      content: title
    },
    {
      property: `og:description`,
      content: metaDescription
    },
    {
      property: `og:type`,
      content: `website`
    },
    {
      name: `twitter:card`,
      content: `summary`
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author
    },
    {
      name: `twitter:title`,
      content: title
    },
    {
      name: `twitter:description`,
      content: metaDescription
    }
  ].concat(meta);

  if (process.env.GATSBY_ENV === "production") {
    metaData.push({
      name: `p:domain_verify`,
      content: "055ac7b4b7519f2c8eb007b5a79bf973"
    });

    metaData.push({
      name: `facebook-domain-verification`,
      content: "hx0qo4b7dsvyat9bohe3hrf245uwix"
    });
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaData}
    />
  );
};

export default Seo;
