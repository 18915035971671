import { useContext, useCallback } from "react";
import { ModalContext, ModalProviderProps } from "../contexts/ModalContext";

const useGlobalModal = () => {
  const modalContext = useContext(ModalContext) as ModalProviderProps;
  const { dispatch } = modalContext;
  return useCallback(
    action => {
      dispatch(action);
    },
    [dispatch]
  );
};

export default useGlobalModal;
