import React, { forwardRef } from "react";
import styled from "styled-components";

const Background = styled.div`
  display: none; /* remove this when adding a sale */
  height: 40px;
  width: 100%;
  background-color: #070d0f;
  text-align: center;
  color: white;
  font-size: 1em;
  font-weight: bold;
  line-height: 40px;

  @media screen and (max-width: 768px) {
    height: 30px;
    line-height: 30px;
    font-weight: normal;
  }
`;

const Span = styled.span`
  font-weight: bold;
`;

/**
 * Advertising banner
 *
 * NOTE: get this content from a network request
 */
const Banner = React.memo(
  forwardRef(({ ...rest }: any, ref) => (
    <Background id="site-banner" ref={ref} {...rest}>
      Shop <Span>Curl & Coil Balm</Span> now & grab 25% off
    </Background>
  ))
);

export default Banner;
