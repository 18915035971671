import { ImageConstants } from "./Images";

export default class ProductConstants {
  static Products = {
    TWO_OZ: {
      size: 2,
      price: 22,
      img: ImageConstants.TRAVEL_SIZE,
      alt: "two ounce bottle"
    },
    FOUR_OZ: {
      size: 4,
      price: 30,
      img: ImageConstants.FULL_SIZE,
      alt: "four ounce bottle"
    }
  };

  constructor() {
    throw Error("This class cannot be instantiated");
  }
}
