import React from "react";
import styled from "styled-components";

interface ButtonProps {
  asElem?: string;
  className?: string;
  width?: number;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (event?: any) => void;
}

const StyledButton = styled.button.attrs(() => ({
  type: "button"
}))<ButtonProps>`
  border: none;
  background: #0e0e0e;
  padding: 0.75rem 1rem;
  width: ${props => (props.width ? `${props.width}px` : undefined)};

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: white;

  :focus {
    outline: none;
  }

  /* these values override the defaults */
  &&& {
    background: ${props => (props.disabled ? "#eaebed" : undefined)};

    :hover {
      background: ${props => (props.disabled ? "#eaebed" : undefined)};
    }
  }
`;

const Button = React.memo(
  ({ className, asElem, width, children, disabled, ...rest }: ButtonProps) => (
    <StyledButton className={className} width={width} disabled={!!disabled} {...rest}>
      {children}
    </StyledButton>
  )
);

export { Button, ButtonProps };
