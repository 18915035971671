export const ImageConstants = {
  GROUP: "https://static.wavyformula.com/images/jar-group-raw.png",
  TRAVEL_SIZE: "https://static.wavyformula.com/images/wavy-jar-travel-web.jpg",
  TRAVEL_TOP: "https://static.wavyformula.com/images/jar-top.png",
  FULL_SIZE: "https://static.wavyformula.com/images/wavy-jar-full-web-test1.jpg",
  FULL_TOP: "https://static.wavyformula.com/images/jar-top.png",
  DICE: "https://static.wavyformula.com/images/dice-2.png",
  BOX: "https://static.wavyformula.com/images/wavy-box.png",
  STACKED: "https://static.wavyformula.com/images/stacked-jars-web.jpg",
  FUN: "https://static.wavyformula.com/images/fun-2.png",
  PORTRAIT: "https://static.wavyformula.com/images/portrait-2.png",
  SWATCH: "https://static.wavyformula.com/images/swatch-web.jpg",
  COCONUT: "https://static.wavyformula.com/images/wavy-coconut.jpg",
  WASH: "https://static.wavyformula.com/images/wash.jpg",
  BARBER: "https://static.wavyformula.com/images/barber-web.jpg",
  FRIZZY: "https://static.wavyformula.com/images/frizzy.jpg",
  PAPER: "https://static.wavyformula.com/images/wavy-paper-design.png",
  CREAM: "https://static.wavyformula.com/images/cream.png",
  SILHOUETTE: "https://static.wavyformula.com/images/our-story-silhouette.jpg",
  COUNTER: "https://static.wavyformula.com/images/counter.jpg",
  MAIN_CAROUSEL_1: "https://static.wavyformula.com/images/products-industrial.jpg",
  MAIN_CAROUSEL_2: "https://static.wavyformula.com/images/heroes2.jpg",
  MAIN_CAROUSEL_3: "https://static.wavyformula.com/images/carousel3.jpg",
  MAIN_CAROUSEL_4: "https://static.wavyformula.com/images/carousel4.jpg",
  MAIN_CAROUSEL_5: "https://static.wavyformula.com/images/heroes1.jpg",
  MAIN_CAROUSEL_6: "https://static.wavyformula.com/images/carousel5.jpg",
  MAIN_CAROUSEL_7: "https://static.wavyformula.com/images/carousel6.jpg",
  MAIN_CAROUSEL_8: "https://static.wavyformula.com/images/carousel7.jpg"
};
