import React from "react";
import styled from "styled-components";

const Link = styled.a.attrs(() => ({
  rel: "noreferrer"
}))``;

const ReturnPolicy = () => (
  <div>
    <p>
      For the health and safety of our customers, we do not accept returns or exchanges. We will
      replace items if they are defective or damaged. Our policy lasts 30 days. If 30 days have gone
      by since your purchase, unfortunately we can’t offer you a replacement. If you received your
      item and it was defective or damaged, please send us an email at{" "}
      <Link href="mailto:cam@getwavyblue.com">cam@getwavyblue.com</Link> with photo evidence and
      purchase proof and we will follow up with next steps.
    </p>
  </div>
);

export default ReturnPolicy;
