import React, { useEffect, useRef, useState } from "react";
import { Alert as BaseAlert } from "reactstrap";
import styled from "styled-components";

const StyledAlert = styled(BaseAlert)`
  display: none;
`;

const Alert = ({ message }: { message: string }) => {
  const timeoutRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // variables
  const text = JSON.parse(message);
  const style = {
    display: isOpen ? "block" : "none"
  };

  useEffect(() => {
    window.clearTimeout(timeoutRef.current);
    if (text.id && text.message) {
      // show the alert
      setIsOpen(true);

      // hide after 3 seconds
      timeoutRef.current = setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    }
  }, [text.id, text.message]);

  return (
    <StyledAlert color={text.messageType || "success"} style={style}>
      {text.message}
    </StyledAlert>
  );
};

export default Alert;
