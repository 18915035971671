import { Link } from "gatsby";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const NavLink = styled(Link).attrs(() => ({
  className: "nav-link"
}))<{ $color?: string }>`
  font-size: 17px;
  font-weight: 400;
  line-height: 17px;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
  }

  &&& {
    color: ${props => props.$color || "white"};
    padding: 0 1.0625rem;
  }
`;

const ExternalLink = styled(OutboundLink).attrs(() => ({
  target: "_blank",
  rel: "noreferrer"
}))<{ $color?: string }>`
  font-size: 17px;
  font-weight: 400;
  line-height: 17px;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
  }

  &&& {
    color: ${props => props.$color || "white"};
    padding: 0 1.0625rem;
  }
`;

export { NavLink, ExternalLink };
